html {
  body {
    // Font

    .ant-input,
    .ant-card-head-title,
    .ant-table,
    .ant-btn,
    .ant-dropdown-menu-item,
    .ant-pagination,
    .ant-menu,
    .ant-menu-vertical .ant-menu-item, 
    .ant-menu-vertical-left 
    .ant-menu-item, 
    .ant-menu-vertical-right .ant-menu-item, 
    .ant-menu-inline .ant-menu-item, 
    .ant-menu-vertical .ant-menu-submenu-title, 
    .ant-menu-vertical-left .ant-menu-submenu-title, 
    .ant-menu-vertical-right .ant-menu-submenu-title, 
    .ant-menu-inline .ant-menu-submenu-title,
    .ant-card,
    .ant-calendar,
    .ant-calendar-range .ant-calendar-input, 
    .ant-calendar-range .ant-calendar-time-picker-input,
    .ant-popover,
    .ant-checkbox,
    .ant-form label,
    .ant-form-item,
    .ant-card-extra,
    .ant-notification ant-notification-topRight,
    .ant-notification-notice-with-icon .ant-notification-notice-description,
    .ant-tabs,
    .ant-tabs-nav-container,
    .ant-checkbox-wrapper {
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
    }

    // Font Weight

    .ant-table-thead > tr > th,
    h1, h2, h3, h4, h5, h6,
    .ant-card-head-title {
      font-weight: 600;
    }

    // Card

    .ant-card {
      box-shadow: 0 2px 2px rgba(0,21,41,.08);
      border-radius: 4px;
      border: none !important;
    }

    .ant-card-extra i {
      font-size: 14px;
    }

    .ant-card .ant-card-head {
      display: flex;
      align-items: center;
      min-height: 48px;
      max-height: 48px;

      .ant-card-head-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      .ant-card-head-title {
        padding: 12px 0;
      }

      .ant-card-extra {
        display: flex;
        
        i {
          font-size: 16px;
        }
      }
    }

    // Table

    .ant-pagination-prev,
    .ant-pagination-item,
    .ant-pagination-next {
      line-height: 28px;
      min-width: 28px;
      height: 28px;
    }

    .ant-table-thead > tr > th, 
    .ant-table-tbody > tr > td {
      padding: 12px;
    }

    .ant-table-thead > tr > th .ant-table-header-column,
    .ant-table-column-has-actions ant-table-column-has-sorters,
    .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters,
    .ant-table-header-column,
    .ant-table-column-sorters,
    .ant-table-thead > tr > th .ant-table-column-sorter {
      display: flex;
      align-items: center;
    }

    .ant-table-column-sorter {
      margin-top: -4px;
    }

    .ant-table-pagination.ant-pagination {
      margin-bottom: 0;
    }

    .ant-pagination-item-active {
      font-weight: normal;
    }

    .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th {
      padding: 10px 8px;
    }
    
    .ant-table-bordered .ant-table-thead > tr > th, 
    .ant-table-bordered .ant-table-tbody > tr > td {
      border-right: none;
    }
    
    .ant-table-small>.ant-table-content>.ant-table-body {
      margin: 0;
    }

    // Notification

    .ant-notification-notice {
      padding-left: 16px;
    }

    .ant-notification-notice-icon {
      font-size: 20px;
      margin-top: 2px;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-message {
      font-size: 14px;
      line-height: 1.2;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-message,
    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 40px;
    }

    // Tabs

    .ant-tabs {
      overflow: visible;
    }

    // Input

    .ant-form-item-required::before,
    .ant-form-item-label > label::after {
      content: none;
    }

    .ant-card-body .parameters-form .ant-form-item .ant-form-item-label {
      margin-bottom: 4px;
    }
  }
}