.RevenueProtectionPage { 
  max-width: calc(100vw - 48px); 
  padding-top: 41px;

  .tabs {
    display: flex;
    background: #fff;
    position: fixed;
    top: 56px;
    left: 256px;
    right: 0;
    box-shadow: 0 2px 2px rgba(0, 21, 41, 0.08);
    z-index: 4;
    border-top: 1px solid #ebecf1;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid transparent;
      padding: 0 24px;
      color: rgba(0, 0, 0, 0.65);

      &[disabled] {
        color: rgba(0,0,0,.25);
      }
    }
  }
}