.SidenavComponent {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  width: 256px;
  box-shadow: 2px 0 6px rgba(0, 21, 41, .35);

  > .logo {
    display: flex;
    align-items: center;
    position: relative;
    height: 56px;
    padding-left: 24px;
    background: #001529;

    > img {
      height: 20px;
    }

    > .name {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;
      left: 56px;
      background: #001529;
      position: absolute;
      right: 0;
      top: 3px;
      bottom: 0;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
}