@import 'overrides';
@import 'variables';

body {
  background: #f0f2f5;
  color: $font-color;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
}

p {
  line-height: 28px;
}