@import '../../../../../../styles/variables';

.RevenueProtectionPagePieChartComponent {
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: $font-size-small;

  > .graph {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    width: 200px;
    height: 200px;
    max-width: 200px;
    min-width: 200px;
    margin: 0 auto;

    > .segment {
      --a: calc(var(--overHalf, 0) * -100%);
      --b: calc((1 + var(--overHalf, 0)) * 100%);
      --degrees: calc((var(--offset, 0) / 100) * 360);
      clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
      transform-origin: 50% 100%;

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $theme-color;
      }

      &:before {
        --degrees: calc(((var(--size, 45) - 1) / 100) * 360);
        transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
        transform-origin: 50% 0%;
      }

      &:after {
        opacity: var(--overHalf, 0);
      }

      &:nth-child(1) {
        &:after,
        &:before {
          background: $chart-color-one;
        }
      }

      &:nth-child(2) {
        &:after,
        &:before {
          background: $chart-color-two;
        }
      }

      &:nth-child(3) {
        &:after,
        &:before {
          background: $chart-color-three;
        }
      }

      &:nth-child(4) {
        &:after,
        &:before {
          background: $chart-color-four;
        }
      }

      &:nth-child(5) {
        &:after,
        &:before {
          background: $chart-color-five;
        }
      }

      &:nth-child(6) {
        &:after,
        &:before {
          background: $chart-color-six;
        }
      }
    }

    > .center {
      position: absolute;
      top: 24px;
      bottom: 24px;
      left: 24px;
      right: 24px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > .labels {
    height: 200px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: auto;
    padding: 8px 0;

    > .label {
      display: flex;
      align-items: center;
      flex: 1;

      > .dot {
        width: 8px;
        height: 8px;
        background: $theme-color;
        border-radius: 50%;
        margin-right: 8px;
      }

      > .percentage {
        color: $font-color-light;
        margin-left: 8px;
      }

      &:nth-child(1) {
       > .dot {
          background: $chart-color-one;
        }
      }

      &:nth-child(2) {
       > .dot {
          background: $chart-color-two;
        }
      }

      &:nth-child(3) {
       > .dot {
          background: $chart-color-three;
        }
      }

      &:nth-child(4) {
       > .dot {
          background: $chart-color-four;
        }
      }

      &:nth-child(5) {
       > .dot {
          background: $chart-color-five;
        }
      }

      &:nth-child(6) {
       > .dot {
          background: $chart-color-six;
        }
      }
    }
  }
}