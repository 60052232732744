$theme-color: #1890ff;
$font-color: rgba(0,0,0,.75);
$font-color-light: rgba(0,0,0,.45);
$border-color: #ebecf1;

$font-size-small: 11px;
$font-size: 12px;

$chart-color-one: rgb(24, 144, 255);
$chart-color-two: rgb(19, 194, 194);
$chart-color-three: rgb(47, 194, 91);
$chart-color-four: rgb(250, 204, 20);
$chart-color-five: rgb(240, 72, 100);
$chart-color-six: rgb(133, 67, 224);
$chart-color-seven: rgb(153, 153, 255);