.RevenueProtectionPageParametersComponent {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -24px 0 0;

    > * {
      width: calc(100% / 3 - 24px);
      margin: 0 24px 24px 0;

      .checkbox {
        height: 32px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebecf1;
      }
    }
  }
}