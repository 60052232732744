.DetailsComponentSplitFaresChartComponent {
  > .info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid #ebecf1;
    border-bottom: 1px solid #ebecf1;
    margin: -24px -24px 0 -24px;
    font-size: 11px;

    > .trip {
      display: flex;
      margin-right: 24px;

      > *:not(:last-child) {
        margin-right: 8px;
      }
  
      .icon {
        height: 18px;
        display: flex;
        align-items: center;
  
        > svg {
          height: 16px;
          width: 16px;
        }
      }
  
      .toc {
        color: rgba(0,0,0,.45);
      }
    }

    > .legend {
      display: flex;

      > *:not(:last-child) {
        margin-right: 16px;
      }

      > .key {
        display: flex;
        align-items: center;
        position: relative;

        &:before {
          content: "";
          width: 8px;
          min-width: 8px;
          height: 8px;
          background: #bbb;
          margin-right: 8px;
          border-radius: 8px;
        }
      }

      > .key-1 {
        &:before {
          background: rgb(47, 194, 91);
        }
      }

      > .key-2 {
        &:before {
          background: rgb(24, 144, 255);
        }
      }
    }
  }

  > .content {
    display: flex;

    > .details {
      font-size: 10px;
      font-weight: 600;
      padding: 24px 24px 0 24px;
      margin: 0 0 -24px -24px;
      border-right: 1px solid #ebecf1;

      > .detail {
        display: flex;
        align-items: flex-start;
        height: 80px;

        &:last-of-type {
          height: auto;
        }
  
        > *:not(:last-child) {
          margin-right: 4px;
        }

        &:not(:last-of-type) {
          &:before {
            content: "";
            border-left: 2px dashed #ebecf1;
            height: calc(100% - 16px);
            margin-top: 18px;
            margin-right: -9px;
          }
        }

        &:last-of-type {
          &:before {
            content: "";
            margin-right: -9px;
            border-left: 2px dashed transparent;
          }
        }
  
        &:after {
          content: "";
          position: absolute;
          left: 111px;
          right: 0;
          border-top: 1px dashed #ebecf1;
          margin-top: 9px;
        }
  
        > .icon {
          display:flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 18px;
          
          > svg {
            width: 16px;
            height: 16px;
          }

          > .dot {
            display:flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
    
            &:after {
              content: "";
              width: 4px;
              height: 4px;
              border-radius: 50%;
              border: 2px solid #666;
            }
          }
        }
  
        .time {
          width: 24px;
          text-align: center;
        }
  
        .station {
          color: rgba(0,0,0,.45);
          width: 21px;
          text-align: center;
        }
      }
    }
    
    > .chart {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-x: auto;
      margin: 0 -24px -24px 0;
      padding: 24px 0 16px 16px;
      font-size: 10px;
      font-weight: 600;
  
      > .graph {
        display: flex;
        flex: 1;
        margin-top: 10px;

        > .bar {
          display: flex;
          flex-direction: column;
          max-width: 116px;
          min-width: 116px;
          flex: 1;
          padding-right: 16px;
          margin: 0 auto;
          position: relative;

          > .title {
            position: absolute;
            bottom: calc(100% + 8px);
            width: 100%;
            text-align: center;
            padding-right: 16px;
          }

          > .segment {
            display: flex;
            flex-direction: column;
            position: relative;
            border-radius: 4px;
            text-align: left;
            padding: 8px;

            > .setter {
              opacity: 0.6;
            }
            
            &:not(:last-child) {
              border-bottom: 1px dashed #fff;
            }
          }
        }
      }
  
      > .totals {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;

        &:first-of-type {
          padding-left: 1px;
        }

        > .total {
          max-width: 116px;
          min-width: 116px;
          padding-right: 16px;
          flex: 1;
          text-align: center;
          margin: 0 auto;

          > .commission {
            color: rgba(0,0,0,.45);
          }
        }
      }
    }
  }
}