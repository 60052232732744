.TopActionsPage {
  max-width: calc(100vw - 48px);
  
  .parameters {
    display: flex;
    align-items: center;

    > .ant-calendar-picker {
      margin-right: 16px;
    }
  }
}