.NetworkExplorerPage {
  
  > .cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -24px -24px 0;

    > .card {
      width: calc(50% - 24px);
      margin: 0 24px 24px 0;
    }
  }
}