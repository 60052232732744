.RevenueProtectionPageMatrixComponent {

  > .cards {
    display: flex;
    margin: 0 -24px 0 0;

    > * {
      flex: 1;
      margin: 0 24px 24px 0;
    }
  }

  .card-actions {
    display: flex;

    > * {
      margin-left: 16px;
    }
  }

  .matrix {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    > .x-label {
      font-weight: 600;
      position: absolute;
      top: -11px;
      left: calc(50% - 52px);
      font-weight: 600;
    }

    > .y-label {
      font-weight: 600;
      position: absolute;
      top: calc(50% - 46px);
      left: -50px;
      transform: rotate(270deg);
      font-weight: 600;
    }

    > .x-axis {
      display: flex;
      background: #fff;
      position: sticky;
      top: 97px;
      z-index: 1;
  
      > .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        flex: 1;
        border: 1px solid #ebecf1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:first-of-type {
          border-radius: 4px 0 0 0;
        }

        &:last-of-type {
          border-radius: 0 4px 0 0;
        }

        &:not(:last-of-type) {
          border-right: none;
        }
      }
    }
  
    > .cells {
      display: flex;
      flex: 1;
      background: #f0f2f5;
      border-left: 1px solid #ebecf1;
      border-bottom: 1px solid #ebecf1;
      border-radius: 0 0 4px 4px;

      > .column {
        display: flex;
        flex-direction: column;
        flex: 1;

        > .cell {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          flex: 1;
          cursor: pointer;
          height: 36px;
          min-height: 36px;
          flex: 1;
          border-right: 1px solid #ebecf1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-height: 36px;
          

          &:not(:first-of-type) {
            border-top: 1px solid #ebecf1;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .side-menu {
    min-width: 300px;
    border-left: 1px solid #ebecf1;
    margin: -24px -24px -24px 24px;
    padding-bottom: 24px;

    > .legend {
      padding: 24px;
      border-bottom: 1px solid #ebecf1;
      border-top: 1px solid #ebecf1;
      background: #fff;

      > .heading {
        text-align: center;
        font-weight: 600;
      }

      > .key {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
      }

      > .blocks {
        display: flex;
        margin: 16px 0;

        > * {
          margin: 0 auto;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;

          &:before {
            position: absolute;
            bottom: -22px;
            white-space: nowrap;
          }

          &:nth-child(1) {
            background: rgb(82, 196, 26);
            
            &:before {
              content: "None";
            }
          }

          &:nth-child(2) {
            background: rgba(250, 214, 20, 1);

            &:before {
              content: "Low";
            }
          }

          &:nth-child(3) {
            background: rgb(250, 173, 20);

            &:before {
              content: "Medium";
            }
          }

          &:nth-child(4) {
            background: rgb(250, 84, 28);

            &:before {
              content: "High";
            }
          }
        }
      }
    }

    > .option {
      padding: 24px;
      border-bottom: 1px solid #ebecf1;
      background: #fff;

      > .heading {
        font-weight: 600;
        text-align: center;
      }

      > .form {
        margin-top: 16px;
        display: flex;
        justify-content: center;

        > * {
          flex: 1;
        }
      }
    }
  }
}