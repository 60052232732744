.HeaderComponent {
  position: fixed;
  left: 256px;
  top: 0;
  right: 0;
  z-index: 4;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  > i {
    margin-right: 16px;
    font-size: 16px;
    cursor: pointer;
  }

  > h3 {
    margin: 0;
  }

  > img {
    height: 24px;
  }
}