@import '../../../../../../styles/variables';

.RevenueProtectionPageBarChartComponent {
  display: flex;
  position: relative;
  height: 200px;
  width: 100%;
  font-size: $font-size-small;

  > .y-axis {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    height: 100%;

    > .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      line-height: 16px;
      width: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-of-type {
        margin-bottom: -6px;
      }

      &:not(:last-of-type) {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          left: 32px;
          margin-top: 8px;
          border-bottom: 1px dashed $border-color;
        }
      }
    }
  }

  > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    > .graph {
      display: flex;
      flex: 1;

      > .bar {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        flex: 1;
        padding: 8px 8px 0 8px;

        > .segment {
          flex: 1;
          background: $chart-color-six;
          max-width: 80px;
        }
      }
    }

    > .x-axis {
      display: flex;
      align-items: center;
      padding-top: 8px;
      border-top: 1px solid $border-color;

      > .value {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}